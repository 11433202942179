import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Header from "../components/Header"
import Breadcrumbs from "../components/Breadcrumbs"
import InternalCards from "../components/InternalCards"
import Cta from "../components/Cta"
import Ready from "../components/Ready"
import Features from "../components/Features"

export const ServicesPageTemplate = ({
  title,
  subtitle,
  services,
  breadcrumbs,
}) => (
  <>
    <Header title={title} subtitle={subtitle}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 35">
        <path d="M477.31 297.18c-37.511 5.5-108.778-34.615-198.932-35.019-90.054-.487-199.095 38.82-212.71 24.49-13.56-14.19 68.152-82.073 47.018-131.826S-32.482 73.309-49.778 47.91C-67.174 22.595 22.27 3.56 99.907 5.992c77.638 2.432 143.314 26.276 196.473 28.073 53.312 1.85 93.898-18.539 152.377-27.264 58.379-8.64 134.342-5.726 174.107 15.19 39.865 20.834 43.223 59.56 20.606 90.448-22.772 30.835-71.52 53.833-97.977 90.878-26.557 37.13-30.415 88.332-68.182 93.862z" />
      </svg>
    </Header>
    <Breadcrumbs data={breadcrumbs} />
    <Features data={services} />
    <InternalCards locations recycling fundraising />
    <Ready>
      <Cta href="https://app.re-collect.com.au/register">Get started</Cta>
    </Ready>
  </>
)

const ServicesPage = ({
  data: {
    markdownRemark: {
      frontmatter: { title, subtitle, seoDesc, services },
    },
  },
  location,
}) => {
  const breadcrumbs = location.pathname.split("/")
  return (
    <Layout
      title="Recycling Pickup Services | ReCollect | Book A Pickup Today"
      seoDesc={seoDesc}
    >
      <ServicesPageTemplate
        title={title}
        subtitle={subtitle}
        services={services}
        breadcrumbs={breadcrumbs}
      />
    </Layout>
  )
}

export default ServicesPage

export const PageQuery = graphql`
  query servicesQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "services-page" } }) {
      frontmatter {
        title
        subtitle
        seoDesc
        services {
          title
          subtitle
          data {
            name
            url
            image {
              relativePath
            }
            body
            data
          }
        }
      }
    }
  }
`
